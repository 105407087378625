
import { Component, Vue, Watch } from 'vue-property-decorator';
import { readTickets, readSiteCode, readSounds, readSites, readFirstNotification } from '@/store/main/getters';
import {
  dispatchGetTickets, dispatchUpdateTicket,
  dispatchCreateTicket, dispatchDeleteTicket, dispatchGetSounds, dispatchMoveUpTicket, dispatchMoveDownTicket,
} from '@/store/main/actions';
import { ITicketCreate, ITicketUpdate, ITicket } from '@/interfaces';
import { apiUrl } from '@/env';
import AudioRecorder from 'vue-audio-recorder-x';
import { commitAddNotification } from '@/store/main/mutations';

@Component
export default class TicketPricing extends Vue {
  public dialogCreateUpdate: boolean = false;
  public dialogDelete: boolean = false;
  public headers = [
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Soundbite', value: 'sound', sortable: false },
    { text: 'Price', value: 'unit_price', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
  public editedIndex: number = -1;
  public editedItem = {
    code: 0,
    type: '',
    displayorder: 0,
    sound: '',
    unit_price: 0,
  };
  public defaultItem = {
    code: 0,
    type: '',
    displayorder: 0,
    sound: '',
    unit_price: 0,
  };
  public validForm: boolean = false;
  public fileInputSound = null;
  public textRequestCustomRecording: string = '';
  public ticketTypes = [
    {value: 'T', text: 'Ticket'},
    {value: 'M', text: 'Message'},
  ];

  get formTitle() {
    return this.editedIndex === -1 ? 'New Ticket Pricing' : 'Edit Ticket Pricing';
  }

  public ticketTypeLabel(item: ITicket) {
    if (item.type === 'T') {
      return 'Ticket';
    } else {
      return 'Message';
    }
  }

  public sounds(type: string) {
    const sounds = readSounds(this.$store);
    if (type === 'T' && sounds) {
      return sounds.tickets;
    } else if (type === 'M' && sounds) {
      return sounds.messages;
    }
    return [];
  }

  get tickets() {
    return readTickets(this.$store);
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  public async mounted() {
    this.initialize();
  }

  public editItem(item: ITicket) {
    this.editedIndex = this.tickets.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogCreateUpdate = true;
  }

  public deleteItem(item: ITicket) {
    this.editedIndex = this.tickets.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public async deleteItemConfirm() {
    await dispatchDeleteTicket(this.$store, this.editedItem.code);
    this.closeDelete();
  }

  public closeCreateUpdate() {
    this.dialogCreateUpdate = false;
    this.$validator.reset();
    this.fileInputSound = null;
    this.textRequestCustomRecording = '';
    const audioRecorder = this.$refs.audioRecorder as AudioRecorder;
    if (audioRecorder) {
      audioRecorder.stopRecorder();
      audioRecorder.removeRecord(0);
    }
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$validator.reset();
    this.fileInputSound = null;
    this.textRequestCustomRecording = '';
    const audioRecorder = this.$refs.audioRecorder as AudioRecorder;
    if (audioRecorder) {
      audioRecorder.stopRecorder();
      audioRecorder.removeRecord(0);
    }
    this.$nextTick(() => {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
    });
  }

  public async save() {
    const audioRecorder = this.$refs.audioRecorder as AudioRecorder;
    if (await this.$validator.validateAll()) {
      if (
        !this.editedItem.sound && !this.fileInputSound &&
        !audioRecorder.selected.blob && !this.textRequestCustomRecording
      ) {
        if (!readFirstNotification(this.$store)) {
          const payloadNotification = {
            content: 'Please, select one of the 4 options for Soundbite.',
            color: 'error',
          };
          commitAddNotification(this.$store, payloadNotification);
        }
        return;
      }

      const soundFile = this.fileInputSound ? this.fileInputSound : audioRecorder.selected.blob;
      if (this.editedIndex > -1) {
        const updatedTicket: ITicketUpdate = {
          type: this.editedItem.type,
          sound: this.editedItem.sound ? this.editedItem.sound : '',
          unit_price: this.editedItem.unit_price,
        };
        await dispatchUpdateTicket(
          this.$store, {
            ticketCode: this.editedItem.code, ticket: updatedTicket, soundFile,
            requestCustomRecording: this.textRequestCustomRecording,
          },
        );
      } else {
        const createdTicket: ITicketCreate = {
          type: this.editedItem.type,
          sound: this.editedItem.sound ? this.editedItem.sound : '',
          unit_price: this.editedItem.unit_price,
        };
        await dispatchCreateTicket(
          this.$store, {
            ticket: createdTicket, soundFile, requestCustomRecording: this.textRequestCustomRecording,
          },
        );
      }
      this.closeCreateUpdate();
      await dispatchGetSounds(this.$store);
    }
  }

  public async moveUp(item: ITicket) {
    await dispatchMoveUpTicket(
      this.$store, { ticketCode: item.code },
    );
  }

  public async moveDown(item: ITicket) {
    await dispatchMoveDownTicket(
      this.$store, { ticketCode: item.code },
    );
  }

  public getSoundURL(item: ITicket) {
    if (this.siteCode) {
      if (item.type === 'T') {
        return `${apiUrl}/api/v1/sounds/site/${this.siteCode}/ticket/${item.sound}.mp3`;
      } else {
        return `${apiUrl}/api/v1/sounds/site/${this.siteCode}/message/${item.sound}.mp3`;
      }
    }
  }

  @Watch('dialogCreateUpdate')
  private watchDialog(val: boolean) {
    if (!val) {
      this.closeCreateUpdate();
    }
  }

  @Watch('dialogDelete')
  private watchDialogDelete(val: boolean) {
    if (!val) {
      this.closeDelete();
    }
  }

  private async initialize() {
    if (this.siteCode) {
      await dispatchGetTickets(this.$store);
      await dispatchGetSounds(this.$store);
    }
  }

  @Watch('siteCode')
  private async watchSiteCode(val: string) {
    await this.initialize();
  }
}
